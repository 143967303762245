import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  label?: string
  services: ServiceProps[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  label,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  if (services.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {label ? (
          <FadeInUp>
            <Label dial={4} row>
              {label}
              <Wave />
            </Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        <Services dial={6} row space="between" wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 0 6.111vw 8.5rem;

  @media (max-width: 1199px) {
    padding: 2.5rem 1.5rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-top: 7.25rem;

  @media (max-width: 1199px) {
    display: block;
    width: 100%;
    padding: 0;
  }
`

const Label = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  svg {
    width: 3.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin-left: 1.25rem;
  }

  @media (max-width: 1199px) {
    align-items: flex-start;
    flex-direction: column-reverse;

    svg {
      margin: 0;
    }
  }
`

const Title = styled.h2`
  max-width: 18.125rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  padding-top: 7rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 0;
  }
`

const Services = styled(FlexBox)`
  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`
