import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const RoomsIntro = memo(function RoomsIntro({
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Head>
        {label ? <Label>{label}</Label> : null}

        {title ? <Title>{title}</Title> : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Head>

      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-top: 12.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-top: 10rem;
  }
`

const Head = styled.div`
  padding: 0 1.5rem 5.625rem;
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  max-width: 49.375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin: 1.875rem auto 0;
`

const Background = styled.div`
  height: 45.6vh;
  max-height: 25.625rem;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 7.5rem;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 20rem;
    &:after {
      display: none;
    }
  }
`
