import styled from '@emotion/styled'
import { Mq, Pax } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  pax?: string
  size?: string
}

export const Info = memo(function Info({ pax, size }: Props) {
  if (!pax || !size) {
    return null
  }

  return (
    <Container row wrap>
      {size ? (
        <Item>
          <Mq />
          {size}
        </Item>
      ) : null}

      {pax ? (
        <Item>
          <Pax />
          {pax}
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  @media (max-width: 1199px) {
    display: block;
  }
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  &:nth-of-type(2) {
    margin-left: 2.875rem;
  }

  svg {
    display: block;
    width: auto;
    max-width: 1.875rem;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin: 0 auto 1rem;
  }

  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    margin-top: 1.25rem;
    text-align: left;
    &:nth-of-type(2) {
      margin: 1.25rem 0 0;
    }

    svg {
      width: 1.25rem;
      max-width: none;
      height: auto;
      margin: 0 0.75rem 0 0;
    }
  }
`
