import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 1.5625rem;
  text-align: left;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Icon = styled.img`
  width: 1.875rem;
  height: auto;
  max-height: 1.875rem;
  margin-right: 1rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.0625rem;
`
