import styled from '@emotion/styled'
import { BannerGallery } from 'app/components/BannerGallery'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Info } from './Info'

export interface Props {
  description?: string
  images?: ImageProps[]
  languageCode: string
  pax?: string
  size?: string
  title?: string
  bookingURL?: string
}

export const Room = memo(function Room({
  bookingURL,
  description,
  images,
  languageCode,
  pax,
  size,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {images && images.length > 0 ? <BannerGallery images={images} /> : null}

        <Wrapper row space="between" wrap>
          <LeftSide>
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>

            <Media lessThan="desktopSmall">
              <FadeInUp>
                <Info pax={pax} size={size} />
              </FadeInUp>
            </Media>

            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}

            {bookingURL ? (
              <FadeInUp>
                <CTA
                  label={useVocabularyData('book-now', languageCode)}
                  rel="noopener"
                  target="_blank"
                  URL={bookingURL}
                />
              </FadeInUp>
            ) : null}
          </LeftSide>

          <RightSide>
            <Media greaterThanOrEqual="desktopSmall">
              <FadeInUp>
                <Info pax={pax} size={size} />
              </FadeInUp>
            </Media>
          </RightSide>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 11.25rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 6.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 63.194vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: -5rem auto 0;
  padding: 4.5rem 4.444vw;
  z-index: 3;

  @media (max-width: 1199px) {
    max-width: none;
    margin: auto;
    padding: 3.125rem 1.5rem 2.5rem;
  }
`

const LeftSide = styled.div``

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;

  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.75rem;
      position: relative;
      &:before {
        content: '';
        width: 0.125rem;
        height: 0.125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark3};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }
`

const CTA = styled(Button)`
  margin-top: 1.625rem;
`

const RightSide = styled.div``
